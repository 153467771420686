import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja"
import { max } from "lodash";

$(function() {
    const defaults = {
        defaultDate: 3,
        maxDate: 3,
        minDate: new Date(),
        showMonths: 2,
    }
    const minDate = $('.flatpickr').data('min') ? new Date($('.flatpickr').data('min')) : defaults.minDate;
    const maxDate = $('.flatpickr').data('max') ? new Date($('.flatpickr').data('max')) : minDate.fp_incr(defaults.maxDate);
    const defaultDate = $('.flatpickr').data('default') ? minDate.fp_incr($('.flatpickr').data('default')) : minDate.fp_incr(defaults.defaultDate);
    const showMonths = $('.flatpickr').data('showMonths') || defaults.showMonths;
    const options = {
        defaultDate: defaultDate,
        minDate: minDate,
        maxDate: maxDate,
        showMonths: showMonths,
    }
    function addUpgradeEvent() {
        return $('.flatpickr-day.flatpickr-disabled').on('click', function() {
            if(!$(this).nextAll('.flatpickr-day:not(.flatpickr-disabled)').length) {
                $('.flatpickr-calendar').css('z-index', 1)
                $('#upgradeModal').modal('show');
            }
        })
    }
    function timeWithUnit(time) {
        if(time < 24) {
            return `${time}時間`
        }else {
            return `${time/24}日`
        }
    }
    function paint() {

        if($('.flatpickr-day.selected:not(.hidden)').length <= 0) return

        $('.flatpickr-day:not(.flatpickr-disabled):first').addClass('startRange')
        if($('.flatpickr-day.selected').length <= 0) {
            $('.flatpickr-day:not(.flatpickr-disabled):first').addClass('selected');
        }


        $('.flatpickr-day:not(.flatpickr-disabled,.prevMonthDay,.nextMonthDay)').each((index, element) => {
            $(element).addClass('inRange');
            if($(element).hasClass('selected')) {
                $(element).addClass('endRange');
                return false;
            }
        })
    }
    function fill(date) {
        const target = $('select[name="expire_time"] #custom_time');
        if(date) {
            target.val(date+'d');
            target.html(date+'日');
            target.prop('selected', true);
        }else {
            $(target.siblings().get(0)).prop('selected', true);
        }
    }
    function init() {
        fill(options.defaultDate*24)
        fp.setDate(options.defaultDate);
        paint()
    }
    var config = {
        locale: Japanese,
        showMonths: defaults.showMonths,
        wrap: true,
        closeOnSelect: false,
        minDate: options.minDate,
        dateFormat: "Y/m/d",
        defaultDate: [options.defaultDate],
        enable: [
            function(date) {
                return (date <= options.maxDate)
            }
        ],
        onOpen: [
            function(selectedDates, dateStr, instance) {
                paint();
            },
        ],
        onChange: function(selectedDates, dateStr, instance) {
            $('#expire_time').removeClass('is-invalid')
            if(dateStr) {
                paint();
                let minDate = new Date(instance.config.min);
                let date = new Date(dateStr);
                let expireDate = new Date(
                    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
                    - new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()).getTime()
                ).getDate() -1;
                fill(expireDate);
                if(expireDate <= 0)
                    instance.close();
            }else {
                init();
            }
        },
        onMonthChange: function(selectedDates, dateStr, instance) {
            paint();
        },
        onYearChange: function(selectedDates, dateStr, instance) {
            paint();
        },
    };
    let fp = flatpickr(".flatpickr", config);

    $('.js-flatpickr-close').on('click', function() {
        fp.close();
    });

    $('#expire_time').on('change', function() {
        $('#expire_time').removeClass('is-invalid')
        let minDate = new Date(fp.config.min);
        let date = new Date(minDate.getFullYear(), minDate.getMonth(),  minDate.getDate());
        date.setHours(date.getHours() + $(this).val());
        fp.setDate(date);
        paint();
    })


})
